<template>
  <b-container fluid="md" class="course-tv h-45">
    <div class="background-screen d-flex align-items-end">
      <div class="w-100 p-50 d-flex align-items-center">
        <VuexyLogo
          style="width: 35px; height: 35px;z-index: 2;  box-shadow: 0px 1.09194px 10.27583px rgba(0, 0, 0, 0.56);" />
        <div class="footer-tv bg-white  rounded-sm down-tv" style="background: #EDEDED;">
          <span style="font-size: 9px;">
              {{ hall ? hall.hallOccupancy.title : "" }}
          </span>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import gsap from 'gsap';
import { BContainer, BRow, BCol, BImg, BCard } from "bootstrap-vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    BCard,
    VuexyLogo,
  },
  props: {
    hall: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    addAnimationsFooterFrom() {
      var t1 = gsap.timeline();
      t1.to(".footer-tv", { width: "0%", duration: 1.7, opacity: 0, ease: 'expo' })
      t1.to(".footer-tv", { width: "75%", duration: 1.7, opacity: 1, ease: 'expo' })
    },
  },
  computed: {

  },
  mounted() {
    setInterval(async () => {
      await this.addAnimationsFooterFrom();
    }, 20000);
  }
};
</script>
<style lang="scss">
.course-tv {
  .footer-tv {
    overflow-x: hidden;
    width: 75%;
  }

  .h-row-100 {
    height: 45px !important;
  }

  .background-screen {
    // width: 210px;
    height: 100%;
    background-image: url("../../../assets/images/tv/screen.png");
    background-size: cover;
  }

  .down-tv {
    height: 24px;
    margin-left: -5px;
    padding-left: 6px;

  }
}
</style>