<template>
    <div class="
            bg-primary
            rounded-lg
            d-flex
            justify-content-around
            align-items-center
            pr-50
            pl-50
          " style="height: 100%">
        <div>
            <h3 style="color: aliceblue">رقم ({{ item.id }})</h3>
        </div>
        <div class="h-100" style="background-color: #fff; width: 2px; color: #fff"></div>
        <div>
            <h3 style="color: aliceblue">{{ item.office_id ? `مكتب رقم (${item.office_id})` : `لم يسند لمكتب`}}</h3>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
}
</script>