<template>
  <div class="slider-container" :class="{ 'no-slider': sliders.length === 0 }">
    <!-- <b-button
        v-if="sliders.length > 0"
        @click="next"
        variant="secondary"
        class="btn-icon rounded-circle next-btn"
      >
        <feather-icon size="20" icon="ArrowLeftIcon" />
      </b-button>
      <b-button
        v-if="sliders.length > 0"
        @click="prev"
        variant="secondary"
        class="btn-icon rounded-circle prev-btn"
      >
        <feather-icon size="20" icon="ArrowRightIcon" />
      </b-button> -->
    <div class="css-slider-wrapper">
      <input v-for="(data, i) in sliders" :key="data.slide_id" type="radio" name="slider" class="slide-radio"
        :class="{ 'active-slide-input': activeSlide === i + 1 }" :checked="activeSlide === i + 1"
        :id="`slider_${i + 1}`" />
      <div class="slider-pagination">
        <label v-for="(data, i) in sliders" :key="data.slide_id" :for="`slider_${i + 1}`"
          :class="{ 'active-page': activeSlide === i + 1 }" class="page" @click="changeActiveSlide(i)" />
      </div>
      <div v-for="(data, i) in sliders" :key="data.slide_id" class="slider"
        :class="{ 'active-slide': activeSlide === i + 1 }" :id="`slide${i + 1}`" style="border-radius: 10px" :style="{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)) , url(${data.source})`,
          right: i === 0 ? '0' : `${i}00%`,
        }">
        <div style="" class="container w-100">
          <h2>{{ data.title }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg, BButton } from "bootstrap-vue";

export default {
  components: {
    BImg,
    BButton,
  },
  props: ["sliders"],
  data() {
    return {
      intervalId: null,
      activeSlide: 1,
    };
  },
  methods: {
    next() {
      if (this.activeSlide === this.sliders.length) this.changeActiveSlide(0);
      else this.changeActiveSlide(this.activeSlide);
    },
    prev() {
      if (this.activeSlide === 1) this.changeActiveSlide(this.sliders.length - 1);
      else this.changeActiveSlide(this.activeSlide - 2);
    },
    changeActiveSlide(i) {
      var toShow = document.querySelectorAll(`.slider`);
      toShow.forEach((el) => {
        el.style["-webkit-transform"] = `translateX(${i * 100}%)`;
        el.style["transform"] = `translateX(${i * 100}%)`;
      });
      this.activeSlide = i + 1;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  created() {
    setInterval(this.next, 5000);
  },
};
</script>
<style lang="css">
.next-btn,
.prev-btn {
  margin: 10px;
  position: absolute;
  z-index: 4;
  top: 0%;
  opacity: 0.6;
  transition: all 0.4s ease;
}

.next-btn:hover,
.prev-btn:hover {
  opacity: 1;
}

.next-btn {
  right: 0;
}

.slider-container {
  position: relative;
  height: 400px;
}

@media (min-width: 2000px) {
  .slider-container {
    height: 1500px;
  }
}

.no-slider {
  height: 0px !important;
  background-color: var(--primary);
}

.css-slider-wrapper {
  display: block;

  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.slider {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100%;
  height: 100%;
  background: #e9f3fd;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 0;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: start;
  align-content: center;
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms, transform 1000ms;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.slider>div {
  text-align: start;
}

.slider h2 {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
  opacity: 0;
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
}


@media (min-width: 2000px) {
  .slider h2 {
    font-size: 5.5em;
  }
}

.slider p {
  opacity: 0;
}

.slider h2,
.slider .button {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 800ms;
  transition-delay: 800ms;
}

.slider p {
  -webkit-transition: opacity 800ms;
  transition: opacity 800ms;
  -webkit-transition-delay: 1600ms;
  transition-delay: 1600ms;
}

.control {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  z-index: 55;
}

.control label {
  z-index: 0;
  display: none;
  text-align: center;
  line-height: 50px;
  font-size: 50px;
  color: #fff;
  cursor: pointer;
  opacity: 0.2;
}

.control label:hover {
  opacity: 0.5;
}

.next {
  right: 1%;
}

.previous {
  left: 1%;
}

.slider-pagination {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  z-index: 1000;
}

.slider-pagination label {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  margin: 0 2px;
  border: solid 1px rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.active-page {
  background: rgba(255, 255, 255, 1) !important;
}

.active-slide p,
.active-slide h2,
.slide-radio:checked~.slider .slider-link-button {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .slider h2 {
    font-size: 20px;
  }

  .slider>div {
    padding: 0 2%;
  }

  .control label {
    font-size: 35px;
  }

  .slider .button {
    padding: 0 15px;
  }
}
</style>
