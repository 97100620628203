<template>
  <b-container fluid="md" class="h-100">
    <div ref="myElement" class="h-100">
      <b-row class="h-row-100 header_table_tv" align-h="center">
        <b-col cols="6" class="d-flex align-items-center">
          <h2 class=" title_table">رقم البطاقة</h2>
        </b-col>
        <b-col cols="6" class="d-flex align-items-center">
          <h2 class=" title_table">رقم المكتب</h2>
        </b-col>
      </b-row>

      <b-row v-for="(item, i) in QueueTvWithOutNullOffice" class="row_anami h-row-100" :key="i"
        :style="i % 2 != 0 ? 'background:#EDEDED' : 'background:#FAFAFA'">
        <b-col cols="6" class="d-flex align-items-center">
          <h3 class="pt-20 title_table">{{ item.id }}</h3>
        </b-col>
        <b-col cols="6" class="d-flex align-items-center">
          <h3 class="pt-20 title_table">
            {{ `${item.office_id ? "المكتب رقم (" : "____"}${item.office_id})` }}
          </h3>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg, BCard } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { mapActions, mapGetters } from "vuex";
import gsap from "gsap";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    VuexyLogo,
    BCard,
  },
  methods: {
    ...mapActions("publicPages/screenTV", ["getQueueTv"]),

    animationToRow() {
      document.querySelectorAll(".row_anami").forEach(function (row_anami) {
        gsap.fromTo(
          ".row_anami",
          { y: -30, opacity: 0, scale: 0.8 },
          {
            duration: 2,
            scale: 1,
            opacity: 1,
            delay: 0.3,
            stagger: 0.2,
            y: 0,
            ease: "power4.inOut",
            force3D: true,
          }
        );
      });
    },
    animationFromGetApi() {
      const element = this.$refs.myElement;
      gsap.fromTo(
        element,
        { opacity: 0, scale: 0.7, translateX: "-30%" },
        { opacity: 1, translateX: "0%", scale: 1, duration: 1.2, ease: "power4.inOut" }
      );
    },
    animationToGetApi() {
      const element = this.$refs.myElement;
      gsap.fromTo(
        element,
        { opacity: 1, scale: 1, translateX: "+10%" },
        { opacity: 0, translateX: "0%", scale: 0.5, duration: 1.2, ease: "expo" }
      );
    },
  },
  computed: {
    ...mapGetters("publicPages/screenTV", ["hallOccupancy", "QueueTvWithOutNullOffice"]),
  },

  mounted() {
    this.animationToRow();

    setInterval(async () => {
      await this.animationToGetApi();
      await this.getQueueTv();
      await this.animationFromGetApi();
      await this.animationToRow();
    }, 20000);
  },
};
</script>
<style lang="scss">
.h-row-100 {
  height: 8% !important;
}

.title_table {
  font-size: 1.16em;
}

.header_table_tv {
  background-color: #EDEDED;
  border-bottom: 3px solid #D0A153;
}

@media (min-width: 2000px) {
  .title_table {
    font-size: 6em;
  }
}
</style>
